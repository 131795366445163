import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { CBadge } from "@coreui/react";
import { useSelector } from "react-redux";

export const SidebarNav = ({ items }) => {
  const location = useLocation();
  const permissions = useSelector((state) => state.permissions);
  
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && <small className="text-white">{name}</small>}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item, index) => {
    const { permission, component, name, badge, icon, ...rest } = item;
    const Component = component;

    if (!permissions?.includes(permission)) {
      return;
    }

    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    );
  };

  const navGroup = (item, index) => {
    const { permission, component, name, icon, to, ...rest } = item;
    const Component = component;

    if (!permissions?.includes(permission)) {
      return;
    }

    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
      </Component>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};
