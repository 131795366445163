import { mdiAccountCircleOutline, mdiAlarmCheck, mdiAlertDecagramOutline, mdiAlertOutline, mdiAlertRhombusOutline, mdiAsterisk, mdiAttachmentOff, mdiBankCheck, mdiBatteryAlertVariantOutline, mdiCalendarAlert, mdiCalendarCheck, mdiCarBrakeAlert, mdiEyeCircleOutline, mdiEyeOutline } from "@mdi/js";

const proto = window.location.protocol;
const host = window.location.hostname;
const port = null;
const usingHttps = proto.toLowerCase().endsWith("s:");

export const backendHost = `${proto}//${host}${port ? `:${port}` : ""}`;
export const wsUrl = `${usingHttps ? "wss" : "ws"}://${host}${
  port ? `:${port}` : ""
}/ws`;
console.log(`using HTTPS: ${usingHttps}`);
export const pageSizeOptions = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 40, label: "40" },
  { value: 80, label: "80" },
  { value: 100, label: "100" },
].map((o, idx) => (
  <option key={idx} value={o.value}>
    {o.label}
  </option>
));
export const yesNoOptions = [
  { value: true, label: "Да" },
  { value: false, label: "Нет" },
];
export const colorMapOptions = {
  primary: "Синий",
  secondary: "Серый",
  success: "Зеленый",
  warning: "Желтый",
  danger: "Красный",
  dark: "Черный",
};
export const iconMapOptions = {
  mdiAlertOutline: mdiAlertOutline,
  mdiAlertDecagramOutline: mdiAlertDecagramOutline,
  mdiAlertRhombusOutline: mdiAlertRhombusOutline,
  mdiCarBrakeAlert: mdiCarBrakeAlert,
  mdiEyeOutline: mdiEyeOutline,
  mdiEyeCircleOutline: mdiEyeCircleOutline,
  mdiAccountCircleOutline: mdiAccountCircleOutline,
  mdiAlarmCheck: mdiAlarmCheck,
  mdiAsterisk: mdiAsterisk,
  mdiAttachmentOff: mdiAttachmentOff,
  mdiBankCheck: mdiBankCheck,
  mdiBatteryAlertVariantOutline: mdiBatteryAlertVariantOutline,
  mdiCalendarCheck: mdiCalendarCheck,
  mdiCalendarAlert: mdiCalendarAlert
};
export const modelsMapOptions = {
  equipment: "Виджеты оборудования",
  firmware: "Виджеты программного обеспечения",
  report: "Виджеты отчетов ОЭ",
  dreport: "Виджеты дирекционных отчетов"
}

const textFieldsExpressions = [
  { value: "icontains", label: "Содержит" },
  { value: "not_icontains", label: "Не содержит" },
  { value: "startswith", label: "Начинается с" },
  { value: "not_startswith", label: "Не начинается с" },
  { value: "endswith", label: "Заканчивается на" },
  { value: "not_endswith", label: "Не заканчивается на" },
  {value: "exact", label: "Точно"}
]

export const widgetAllowedFields = {
  equipment: [
    {
      name: "name",
      label: "Наименование",
      expressions: textFieldsExpressions
    },
    {
      name: "hardware_version",
      label: "Аппаратная версия",
      expressions: textFieldsExpressions
    },
    {
      name: "manufacturer__name",
      label: "Производитель",
      expressions: textFieldsExpressions
    },
    {
      name: "equipment_type__name",
      label: "Тип",
      expressions: textFieldsExpressions
    },
    {
      name: "equipment_network__name",
      label: "Сеть",
      expressions: textFieldsExpressions
    },
    {
      name: "is_active",
      label: "Активно",
      expressions: [
        {value: "exact", label: "Точно"}
      ],
      options: yesNoOptions
    },
  ],
  firmware: [
    {
      name: "equipments__equipment_network__name",
      label: "Сеть",
      expressions: textFieldsExpressions
    },
    {
      name: "name",
      label: "Наименование",
      expressions: textFieldsExpressions
    },
    {
      name: "version",
      label: "Версия",
      expressions: textFieldsExpressions
    },
    {
      name: "equipments__name",
      label: "Оборудование",
      expressions: textFieldsExpressions
    },
    {
      name: "classification__name",
      label: "Классификация",
      expressions: textFieldsExpressions
    },
    {
      name: "stage__name",
      label: "Статус",
      expressions: textFieldsExpressions
    },
    {
      name: "ro_number_release",
      label: "Номер РО (релиз)",
      expressions: textFieldsExpressions
    },
    {
      name: "ro_number_trial",
      label: "Номер РО (ОЭ)",
      expressions: textFieldsExpressions
    },
    {
      name: "manufacturer__name",
      label: "Производитель",
      expressions: textFieldsExpressions
    },
  ],
  report: [
    {
      name: "firmware__name",
      label: "Наименование ПО",
      expressions: textFieldsExpressions
    },
    {
      name: "firmware__version",
      label: "Версия ПО",
      expressions: textFieldsExpressions
    },
    {
      name: "equipments__name",
      label: "Оборудование (имя)",
      expressions: textFieldsExpressions
    },
    {
      name: "equipments__hardware_version",
      label: "Оборудование (аппаратная версия)",
      expressions: textFieldsExpressions
    },
    {
      name: "compatible_firmwares__name",
      label: "Совместимое ПО (имя)",
      expressions: textFieldsExpressions
    },
    {
      name: "compatible_firmwares__version",
      label: "Совместимое ПО (версия)",
      expressions: textFieldsExpressions
    },
    {
      name: "directorates__name",
      label: "Дирекция",
      expressions: textFieldsExpressions
    },
  ],
  dreport: [
    {
      name: "report__firmware__name",
      label: "Наименование ПО",
      expressions: textFieldsExpressions
    },
    {
      name: "report__firmware__version",
      label: "Версия ПО",
      expressions: textFieldsExpressions
    },
    {
      name: "directorate__name",
      label: "Дирекция",
      expressions: textFieldsExpressions
    },
    {
      name: "passed",
      label: "Отчет сдан",
      expressions: [
        {value: "exact", label: "Точно"},
      ],
      options: yesNoOptions
    },
    {
      name: "equipment_instances_count",
      label: "Количество узлов",
      expressions: textFieldsExpressions,
    },
    {
      name: "dreportissue",
      label: "Есть замечания",
      expressions: [
        {value: "isnull", label: "Точно"},
      ],
      options: [
        {value: false, label: "Да"},
        {value: true, label: "Нет"},
      ],
    },
    {
      name: "dreportimprovement",
      label: "Есть предложения доработок",
      expressions: [
        {value: "isnull", label: "Точно"},
      ],
      options: [
        {value: false, label: "Да"},
        {value: true, label: "Нет"},
      ],
    },
    

  ]
};
