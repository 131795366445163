import React from "react";
import { CNavGroup, CNavItem } from "@coreui/react";
import Icon from "@mdi/react";
import {
  mdiMicrosoftWindowsClassic,
  mdiChartBoxOutline,
  mdiExpansionCardVariant,
  mdiAccountFilterOutline,
  mdiStickerAlertOutline,
  mdiStickerCheckOutline,
  mdiTools,
  mdiCarSpeedLimiter,
} from "@mdi/js";
import { mdiClipboardEditOutline } from "@mdi/js";

const Page404 = React.lazy(() => import("./components/pages/Page404"));
const LoginView = React.lazy(() => import("./views/accounts/Login"));

const EquipmentTableView = React.lazy(() =>
  import("./views/equipments/EquipmentTableView")
);
const EquipmentDetailView = React.lazy(() =>
  import("./views/equipments/EquipmentDetailView")
);
const EquipmentManageView = React.lazy(() =>
  import("./views/equipments/EquipmentManageView")
);

const FirmwareTableView = React.lazy(() =>
  import("./views/firmwares/FirmwareTableView")
);
const FirmwareDetailView = React.lazy(() =>
  import("./views/firmwares/FirmwareDetailView")
);
const FirmwareManageView = React.lazy(() =>
  import("./views/firmwares/FirmwareManageView")
);

const ReportTableView = React.lazy(() =>
  import("./views/reports/service/ReportTableView")
);
const ReportDetailView = React.lazy(() =>
  import("./views/reports/service/ReportDetailView")
);
const ReportManageView = React.lazy(() =>
  import("./views/reports/service/ReportManageView")
);

const DirectorateReportTableView = React.lazy(() =>
  import("./views/reports/directorate/DirectorateReportTableView")
);
const DirectorateReportDetailView = React.lazy(() =>
  import("./views/reports/directorate/DirectorateReportDetailView")
);
const DirectorateReportManageView = React.lazy(() =>
  import("./views/reports/directorate/DirectorateReportManageView")
);

const WidgetTableView = React.lazy(() => import("./views/widgets/WidgetTableView"))
const WidgetManageView = React.lazy(() => import("./views/widgets/WidgetManageView"))

export const sidebarItems = [
  {
    component: CNavGroup,
    name: "Оборудование",
    to: "/equipments/list",
    icon: <Icon path={mdiExpansionCardVariant} size={1} className="nav-icon" />,
    permission: "view_equipment",
    items: [
      {
        component: CNavItem,
        name: "Создать оборудование",
        permission: "add_equipment",
        to: "/equipments/create",
      },
      {
        component: CNavItem,
        name: "Список оборудования",
        permission: "view_equipment",
        to: "/equipments/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "ПО",
    to: "/firmwares/list",
    icon: (
      <Icon path={mdiTools} size={1} className="nav-icon" />
    ),
    permission: "view_firmware",
    items: [
      {
        component: CNavItem,
        name: "Создать ПО",
        permission: "add_firmware",
        to: "/firmwares/create",
      },
      {
        component: CNavItem,
        name: "Список ПО",
        permission: "view_firmware",
        to: "/firmwares/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Отчеты",
    to: "/reports/list",
    icon: <Icon path={mdiClipboardEditOutline} size={1} className="nav-icon" />,
    permission: "view_report",
    items: [
      {
        component: CNavItem,
        name: "Создать отчет",
        permission: "add_report",
        to: "/reports/create",
      },
      {
        component: CNavItem,
        name: "Список отчетов",
        permission: "view_report",
        to: "/reports/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Дирекционные отчеты",
    to: "/reports/directorates/list",
    icon: <Icon path={mdiStickerCheckOutline} size={1} className="nav-icon" />,
    permission: "view_dreport",
    items: [
      {
        component: CNavItem,
        name: "Список отчетов",
        permission: "view_dreport",
        to: "/reports/directorates/list",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Виджеты",
    to: "/widgets/list",
    icon: <Icon path={mdiCarSpeedLimiter} size={1} className="nav-icon" />,
    permission: "view_widget",
    items: [
      {
        component: CNavItem,
        name: "Список виджетов",
        permission: "view_widget",
        to: "/widgets/list",
      },
      {
        component: CNavItem,
        name: "Создать виджет",
        permission: "add_widget",
        to: "/widgets/create",
      },
    ],
  },
];

export const routes = [
  { path: "*", exact: false, name: "Page404", element: Page404 },
  { path: "/login", exact: false, name: "LoginView", element: LoginView },
  {
    path: "/equipments/list",
    exact: true,
    name: "EquipmentTableView",
    element: EquipmentTableView,
    permission: "view_equipment",
  },
  {
    path: "/equipments/create",
    exact: true,
    name: "EquipmentCreateView",
    element: EquipmentManageView,
    permission: "add_equipment",
  },
  {
    path: "/equipments/:id/detail",
    exact: true,
    name: "EquipmentDetailView",
    element: EquipmentDetailView,
    permission: "view_equipment",
  },
  {
    path: "/equipments/:id/edit",
    exact: true,
    name: "EquipmentEditView",
    element: EquipmentManageView,
    permission: "change_equipment",
  },
  {
    path: "/firmwares/list",
    exact: true,
    name: "FirmwareTableView",
    element: FirmwareTableView,
    permission: "view_firmware",
  },
  {
    path: "/firmwares/:id/detail",
    exact: true,
    name: "FirmwareDetailView",
    element: FirmwareDetailView,
    permission: "view_firmware",
  },
  {
    path: "/firmwares/create",
    exact: true,
    name: "FirmwareCreateView",
    element: FirmwareManageView,
    permission: "add_firmware",
  },
  {
    path: "/firmwares/:id/edit",
    exact: true,
    name: "FirmwareEditView",
    element: FirmwareManageView,
    permission: "change_firmware",
  },

  {
    path: "/reports/list",
    exact: true,
    name: "ReportTableView",
    element: ReportTableView,
    permission: "view_report",
  },
  {
    path: "/reports/:id/detail",
    exact: true,
    name: "ReportDetailView",
    element: ReportDetailView,
    permission: "view_report",
  },
  {
    path: "/reports/create",
    exact: true,
    name: "ReportCreateView",
    element: ReportManageView,
    permission: "add_report",
  },
  {
    path: "/reports/:id/edit",
    exact: true,
    name: "ReportEditView",
    element: ReportManageView,
    permission: "change_report",
  },

  {
    path: "/reports/directorates/:id/detail",
    exact: true,
    name: "DirectorateReportDetailView",
    element: DirectorateReportDetailView,
    permission: "view_dreport",
  },
  {
    path: "/reports/directorates/:id/manage",
    exact: true,
    name: "DirectorateReportManageView",
    element: DirectorateReportManageView,
    permission: "change_dreport",
  },
  {
    path: "/reports/directorates/list",
    exact: true,
    name: "DirectorateReportDetailView",
    element: DirectorateReportTableView,
    permission: "view_dreport",
  },
  {
    path: "/widgets/list",
    exact: true,
    name: "WidgetTableView",
    element: WidgetTableView,
    permission: "view_widget",
  },
  {
    path: "/widgets/create",
    exact: true,
    name: "WidgetCreateView",
    element: WidgetManageView,
    permission: "add_widget",
  },
  {
    path: "/widgets/:id/edit",
    exact: true,
    name: "WidgetEditView",
    element: WidgetManageView,
    permission: "change_widget",
  },
];
