import React from "react";
import { useSelector } from "react-redux";
import Page403 from "./pages/Page403";

const ProtectedRoute = ({ permission, children }) => {
  const permissions = useSelector((state) => state.permissions);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  if(!permission) {
    return children
  }

  if (!permissions?.includes(permission) && isAuthenticated) {
    return <Page403 />;
  }
  return permissions && children;
};
export default ProtectedRoute;
