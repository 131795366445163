import React from "react";
import DefaultLayout from "./layouts/DefautlLayout";
import { useAuth } from "./hooks/Hooks";

const App = () => {
  const { userData } = useAuth({checkAuth: true});

  return <DefaultLayout />
};

export default App;
