import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../utils/api";
import fileDownload from "js-file-download";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";

export const useErrorHandler = () => {
  const toasts = useSelector((state) => state.toasts);
  const dispatch = useDispatch();

  return useCallback((err) => {
    let errorText = "Неизвестная ошибка";
    if (err?.response?.data?.errors) {
      errorText = err.response?.data?.errors?.join("\n");
    }
    if (err?.request && err?.request?.data?.status >= 500) {
      errorText = "Внутренняя ошибка сервера";
    }
    dispatch({
      type: "set",
      toasts: [
        ...toasts,
        { index: toasts.length, text: errorText, color: "danger" },
      ],
    });
  }, []);
};

export const useToast = () => {
  const toasts = useSelector((state) => state.toasts);
  const dispatch = useDispatch();

  return useCallback(
    (text, color = "primary") =>
      dispatch({
        type: "set",
        toasts: [...toasts, { index: toasts.length, text: text, color: color }],
      }),
    []
  );
};

export const useFetch = (url, defaultFilters = null) => {
  const [data, setData] = useState([]);
  const [widgets, setWidgets] = useState([])
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const handleError = useErrorHandler();

  const fetch = (filters = null, showLoading = true) => {
    showLoading && setIsLoading(true);
    api
      .get(url, {
        params: filters
          ? filters
          : defaultFilters
          ? defaultFilters
          : { page_size: 10, page_number: 1 },
      })
      .then((res) => {
        setData(res.data.results);
        setWidgets(res.data.widgets)
        setCount(res.data.count);
        showLoading && setIsLoading(false);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };
  useEffect(() => {
    url && fetch();
  }, [url]);

  return { data, widgets, count, isLoading, hasErrors, denied, fetch };
};

export const useRetrieve = (defaultUrl = null) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const handleError = useErrorHandler();

  const retrieve = ({
    url = null,
    showLoading = true,
    onSucces = null,
    onFail = null,
  }) => {
    showLoading && setIsLoading(true);
    api
      .get(url || defaultUrl)
      .then((res) => {
        setData(res.data);
        showLoading && setIsLoading(false);
        onSucces && onSucces(res);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };

  useEffect(() => {
    defaultUrl && retrieve({ url: defaultUrl });
  }, [defaultUrl]);

  return { data, isLoading, hasErrors, denied, retrieve };
};

export const useFileRetrieve = () => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const handleError = useErrorHandler();

  const fileRetrieve = ({
    url,
    filename,
    showLoading = true,
    params = null,
  }) => {
    showLoading && setInProcess(true);
    api
      .get(url, { params: params && params, responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, filename);
        showLoading && setInProcess(false);
      })
      .catch((err) => {
        showLoading && setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };
  return { inProcess, hasErrors, denied, fileRetrieve };
};

export const useDestroy = (baseUrl) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const handleError = useErrorHandler();
  const toast = useToast();

  const destroy = ({
    id,
    path = "delete",
    successText = "Объект удален",
    onSuccess = null,
    onFail = null,
  }) => {
    setInProcess(true);
    const url = id ? `${baseUrl}/${id}/${path}` : `${baseUrl}/${path}`;
    api
      .delete(url)
      .then((res) => {
        setInProcess(false);
        toast(successText, "success");
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, destroy };
};

export const useSave = (baseUrl) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const handleError = useErrorHandler();
  const toast = useToast();

  const save = ({
    data,
    id = null,
    path = null,
    successText = "Изменения сохранены",
    showSuccessAlert = true,
    onSuccess = null,
    onFail = null,
  }) => {
    let response;
    setInProcess(true);
    if (id) {
      response = api.put(`${baseUrl}/${id}/${path ? path : ""}`, data);
    } else {
      response = api.post(`${baseUrl}/${path ? path : ""}`, data);
    }
    response
      .then((res) => {
        setInProcess(false);
        showSuccessAlert && toast(successText, "success");
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, save };
};

export const useUUID = () => {
  return useMemo(() => uuidv4(), []);
};

export const useAuth = (
  checkAuth = true,
  loginRoute = "/login",
  redirectRoute = "/firmwares/list"
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const routeFrom = useSelector(state => state.routeFrom) || redirectRoute

  const auth = () => {
    if (loginRoute != location.pathname) {
      dispatch({type: "set", routeFrom: location.pathname})
    }
    
    if (!localStorage.getItem("token")) {
      navigate(loginRoute);
      return;
    }

    setIsLoading(true);
    api
      .get("auth/user")
      .then((res) => {
        // Если залогинен и зашел на страницу логина - редирект по дефолту
        if (location.pathname.startsWith("/login")) {
          navigate(routeFrom);
        }

        dispatch({
          type: "set",
          fullName: res.data.full_name,
          isAuthenticated: true,
          permissions: res.data.permissions,
          isStaff: res.data.is_staff,
          directorate: res.data.directorate,
          canPassReports: res.data.can_pass_reports,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch({
          type: "set",
          fullName: null,
          isAuthenticated: false,
          permissions: [],
          isStaff: false,
          directorate: {},
          canPassReports: false
        });
        setIsLoading(false);
        navigate(loginRoute);
      });
  };

  const login = ({ data = {} }) => {
    setIsLoading(true);
    api
      .post("auth/login/", data)
      .then((res) => {
        localStorage.setItem("token", res.data.access);
        dispatch({
          type: "set",
          fullName: res.data.user.full_name,
          isAuthenticated: true,
          permissions: res.data.user.permissions,
          isStaff: res.data.user.is_staff,
          directorate: res.data.user.directorate,
          canPassReports: res.data.user.can_pass_reports,
        });
        navigate(routeFrom);
        setIsLoading(false);
      })
      .catch((err) => {
        setHasErrors(true);
        setIsLoading(false);
      });
  };

  const logout = () => {
    setIsLoading(true);
    api
      .post("auth/logout/")
      .then((res) => {
        localStorage.removeItem("token");
        dispatch({
          type: "set",
          fullName: null,
          isAuthenticated: true,
          permissions: [],
          isStaff: false,
          directorate: {},
          canPassReports: false,
          routeFrom: null
        });
        setIsLoading(false);
        navigate(loginRoute);
      })
      .catch((err) => {
        localStorage.removeItem("token");
        setHasErrors(true);
        setIsLoading(false);
        navigate(loginRoute);
      });
  };

  useEffect(() => {
    auth();
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      dispatch({
        type: "set",
        fullName: null,
        isAuthenticated: false,
        permissions: [],
        isStaff: false,
        directorate: {},
        canPassReports: false,
      });
    }
  }, [localStorage.getItem("token")]);

  return {
    isLoading,
    hasErrors,
    auth,
    login,
    logout,
  };
};

export const usePermission = () => {};
