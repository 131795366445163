import React, { Suspense } from "react";

import { CCol, CToaster } from "@coreui/react";

import { useSelector, useDispatch } from "react-redux";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import NotificationToast from "../components/Toast";
import ProtectedRoute from "../components/ProtectedRoute";

import { Route, Routes } from "react-router-dom";

import useScreenType from "react-screentype-hook";

import { routes } from "../routes";

import "../scss/style.scss";

const DefaultLayout = () => {
  const screenType = useScreenType();
  const toasts = useSelector((state) => state.toasts);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const dispatch = useDispatch();

  const onClose = (index) => {
    dispatch({ type: "set", toasts: toasts.filter((i) => i.index !== index) });
  };

  const tsts = toasts.map((t) => (
    <NotificationToast
      key={t.index}
      index={t.index}
      text={t.text}
      color={t.color}
      onClose={onClose}
      visible={true}
    />
  ));
  return (
    <>
      <Suspense fallback={<CCol />}>
        {isAuthenticated && <Sidebar />}
        <div className="wrapper d-flex flex-column min-vh-100 bg-white">
          {isAuthenticated && <Header />}
          <div className="body flex-grow-1 px-3">
            {isAuthenticated && (
              <CToaster
                className={`px-3 pt-5 position-fixed top-0 end-0${
                  screenType.isMobile ? " w-100" : ""
                }`}
              >
                {tsts}
              </CToaster>
            )}
            <Routes>
              {routes.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    <ProtectedRoute permission={route.permission}>
                      <route.element />
                    </ProtectedRoute>
                  }
                />
              ))}
            </Routes>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default DefaultLayout;
