import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";

import { sidebarItems } from "../routes";
import { SidebarNav } from "./SidebarNav";

import logoLarge from "../assets/img/logo-stk.png";
import logoSmall from "../assets/img/logo-stk-small.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      unfoldable={true}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
      className="bg-info"
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage
          className="sidebar-brand-full text-start"
          src={logoLarge}
          width={150}
          height={35}
        />
        <CImage
          className="sidebar-brand-narrow"
          src={logoSmall}
          width={25}
          height={25}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SidebarNav items={sidebarItems} width={300} height={150} />
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;
// export default React.memo(Sidebar)
