import React from "react";
import { CToast, CToastBody, CToastClose } from "@coreui/react";
import useScreenType from "react-screentype-hook";

const NotificationToast = ({ index, text, color, visible, onClose }) => {
  const screenType = useScreenType();
  return (
    <CToast
      autohide={true}
      visible={visible}
      index={index}
      onClose={(i) => onClose(i)}
      color={color}
      delay={7000}
      className={`rounded-0 text-white align-items-center${
        screenType.isMobile ? " w-100" : ""
      }`}
    >
      <div className="d-flex">
        <CToastBody>{text}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  );
};
export default NotificationToast;
