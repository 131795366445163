import React from "react";
import { NavLink, unstable_useBlocker, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CDropdownItem,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import api from "../utils/api";
import { cilMenu } from "@coreui/icons";
import { useAuth } from "../hooks/Hooks";
import { useEffect } from "react";

const Header = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const fullName = useSelector((state) => state.fullName)
  const {logout} = useAuth(false)

  return (
    <CHeader position="sticky" className="mb-4 shadow">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/"></CHeaderBrand>
        <CHeaderNav>
          <CNavItem>
            <CDropdown>
              <CDropdownToggle color="transparent" size="sm">
                {fullName}
              </CDropdownToggle>
              <CDropdownMenu className="rounded-0 p-0">
                <CDropdownItem>
                  <CButton size="sm" color="transparent" onClick={logout}>
                    Выход из учетной записи
                  </CButton>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default Header;
