import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";

const Page403 = () => {
  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <span className="clearfix">
              <h1 className="float-start display-3 me-4">403</h1>
              <h4 className="pt-4">Доступ запрещен</h4>
              <p className="text-medium-emphasis float-start">
                Ваша учетная запись не имеет прав на просмотр/редактирование
                данного раздела
              </p>
            </span>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page403;
